// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")

// require("jquery")
// require("jquery-ui")
require("easy-autocomplete")
import "packs/portal-search"

// import "foundation-sites"
// require("src/application")
require("packs/jquery.easy-ticker.js")
require("packs/jquery.timeago.js")
require("packs/jquery.easing.js")


//////Removed in 2021 - Foundation CSS was used for users blue name on hovers - find alternative
//////that doesn't need a 100KB file!
// Foundation.addToJquery($);
// $(document).on('turbolinks:load', function() {
//   $(document).foundation()
// });

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)






$(document).on('turbolinks:load', function() {
	$("#siteloader").hide();
	// $(".homepage-content, .ftr-wrap").fadeIn("fast");
	$(".homepage-content, .ftr-wrap").show();
	
  document.addEventListener('turbolinks:request-start', function() {
    // $(".homepage-content, .ftr-wrap").fadeOut("fast");
		$(".homepage-content, .ftr-wrap").hide();
		$("#siteloader").fadeIn("fast");
  });

  // document.addEventListener("turbolinks:request-end", function(){
  // 	  // $(".homepage-content, .ftr-wrap").fadeIn("slow");
  // 		$("#siteloader").hide();
  // 		$(".homepage-content, .ftr-wrap").show();
  // });
});













$(document).on('turbolinks:load', function() {
  jQuery("time.timeago").timeago();
});




// send get forms through turbolinks
$(document).on("submit", "form[data-turboform]", function(e) {
    Turbolinks.visit(this.action+(this.action.indexOf('?') == -1 ? '?' : '&')+$(this).serialize());
    return false;
});





// reset filters
$(document).on('turbolinks:load', function() {
	
  var prev;    

  $('li.fby-selected a').hover(function(){
  prev = $(this).text();
      $(this).text("Clear Filter");
  }, function(){
      $(this).text(prev)
  });
	
});


// For new threadbit flags
// $(document).on('turbolinks:load', function() {
//
// 	var last_visit_id_div = document.getElementsByClassName("last-visit-id");
// 	if (last_visit_id_div) {
// 		var first = last_visit_id_div[0];
// 		var last_visit_id = parseInt(first.id);
// 		var divs = document.getElementsByClassName("pb-label-new");
// 		var item;
// 		for (item = 0; item < divs.length; item++) {
// 	  var actual_id = divs[item].id
// 	  	var id_val = parseInt(actual_id.replace(/\D/g,''));
// 	  	if (id_val < last_visit_id) {
// 	      document.getElementById(actual_id).style.display = 'block';
// 			}
// 		}
// 	}
//
// });

// For 'new' threadbit flags
$(document).on('turbolinks:load', function() {
	
	// alert("loaded?")
	var last_visit_id_div = document.getElementsByClassName("last-visit-id");
	if (last_visit_id_div) {
		var first = last_visit_id_div[0];
		var last_visit_id = parseInt(first.id);
		var divs = document.getElementsByClassName("pb-label-new");
		var item;
		for (item = 0; item < divs.length; item++) {
			var actual_id = divs[item].id;
			// console.log("last_visit_id: " + last_visit_id);
			// console.log("actual_id: " + actual_id);
	  	var id_val = parseInt(actual_id.replace(/\D/g,''));
			// console.log("id_val: " + id_val);
	  	if (parseInt(id_val) > parseInt(last_visit_id)) {
	      document.getElementById(actual_id).style.display = 'block';
			}
		}
	}
	
});



///////////////////////
// Portal faves system
//////////////////////
// Lists all saved portals
$(document).on('turbolinks:load', function() {
  
  if (document.getElementById('users-saved-portals')) {
    var saved_portals = document.getElementById('users-saved-portals').innerHTML.trim().split(',');
    for (const portal of saved_portals) {
      var ul = document.getElementById('saved-portals');
      var li = document.createElement("li");
      var aTag = document.createElement('a');
      aTag.setAttribute('href', ("/" + portal.trim()));
      aTag.innerText = ("/" + portal.trim());
      li.appendChild(aTag);
      ul.appendChild(li);
    }
    var cln = ul.cloneNode(true);
    document.getElementById('saved-portals-mobile').replaceWith(cln);
  }
  
	
  // shows the heart status
  if (document.getElementsByClassName("portal-slug")[0]) {
  	var portal_slug = document.getElementsByClassName("portal-slug")[0].innerHTML;
  	if (portal_slug && saved_portals && saved_portals.includes(portal_slug)) {
      var heart_div = document.getElementsByClassName("portal-heart-saved")[0];
      var aTag = document.createElement('a');
      aTag.setAttribute('href',("/" + portal_slug + "/unsave"));
      aTag.innerHTML = "<span title='Remove fave'></span>";
      heart_div.appendChild(aTag);
      heart_div.style.display = 'block';
  	} else {
      var heart_div = document.getElementsByClassName("portal-heart")[0];
      var aTag = document.createElement('a');
      aTag.setAttribute('href',("/" + portal_slug + "/save"));
      aTag.innerHTML = "<span title='Add fave'></span>";
      heart_div.appendChild(aTag);
      heart_div.style.display = 'block';
    }
  }
  
  // if (document.getElementsByClassName("portal-slug")[0]) {
  //   var portal_slug = document.getElementsByClassName("portal-slug")[0].innerHTML;
  //   // returns a string, eg "groovy"
  //   if (portal_slug) {
  //     var heart_div = document.getElementsByClassName("portal-heart")[0];
  //     var aTag = document.createElement('a');
  //     aTag.setAttribute('href',("/" + portal_slug + "/save"));
  //     aTag.innerHTML = "<span title='Add fave'></span>";
  //     heart_div.appendChild(aTag);
  //   }
  // }

});







$(document).on('turbolinks:load', function() {
    // Easing
    $.extend(jQuery.easing,{
      easeInOutExpo: function (x, t, b, c, d) {
        if (t==0) return b;
        if (t==d) return b+c;
        if ((t/=d/2) < 1) return c/2 * Math.pow(2, 10 * (t - 1)) + b;
        return c/2 * (-Math.pow(2, -10 * --t) + 2) + b;
      }
    });
    
    var header = $('.top-bar').outerHeight() + 91;
    
    // Add smooth scrolling to all links
    // $('.home-trending-anchors a, .home-trending h2 a').click(function () {
    $('.home-trending-anchors a, .home-trending h2 a, .ftr-base-wrap a, .inpage-btt a').click(function () {
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                $('html, body').animate({
                    scrollTop: target.offset().top - header
                }, 350, 'easeInOutExpo');
                return false;
            }
        }
    });
});


$(document).on('turbolinks:load', function() {
	var dd = $('.vticker').easyTicker({
		direction: 'up',
		easing: 'easeInOutBack',
		speed: 'slow',
		interval: 2000,
		height: 'auto',
		visible: 1,
		mousePause: 0,
		controls: {
			up: '.up',
			down: '.down',
			toggle: '.toggle',
			stopText: 'Stop !!!'
		}
	}).data('easyTicker');
});


$(document).on('turbolinks:load', function() {
  // When the user scrolls the page, execute myFunction 
  window.onscroll = function() {myFunction()};

  // Get the navbar
  var topbar = document.getElementById("topBar");

  // Get the offset position of the navbar
  var sticky = topbar.offsetTop;

  // Add sticky class to topbar when reaching its scroll position. Remove "sticky" when leaving the scroll position
  function myFunction() {
    if (window.pageYOffset >= sticky) {
      topbar.classList.add("sticky")
    } else {
      topbar.classList.remove("sticky");
    }
  }
});

$(document).on('turbolinks:load', function() {
	// Filter Menu on Mobile
	$("#mobile_filter_toggle").on("click", function(e) {
		e.preventDefault(),
		$(this).closest('#mobile_filter_toggle_wrap').next("#mobile_filter_list").slideToggle();
		var t = "Hide Menu" == $(this).text() ? "Filter By Category" : "Hide Menu";
		$(this).text(t);
	})
});


